import { useState } from "react";
import { AdminAccess } from "../components/admin/AdminAccess";
import { AdminDashboard } from "../components/admin/AdminDashboard";

const ACCESS_CODE = "F0l!n@2024"

const AdminPage = () => {
  const[accessCode , setAccessCode] = useState("")

  if(accessCode === ACCESS_CODE){
    return <AdminDashboard />
  } else {
    return <AdminAccess setAccessCode={setAccessCode}/>
  }
  //return (accessCode === ACCESS_CODE ? <AdminAccess setAccessCode={setAccessCode}/> : <div>Looged in</div>)
}

export default AdminPage;

