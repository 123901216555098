const API_URL = 'https://folinplatform.azurewebsites.net/api'

export const getSalesSummary = async (name: string) => {
    return fetch(`${API_URL}/Influencers/${name}/sales`).then(response => response.json())
}

export const loginInfluencer = (userName: string, password: string) => {
    const result = validInfluencers.find(x => x.email === userName && x.password === password)
    if (result) {
        const _result = { ...result }
        _result.password = "****";
        return _result
    }
}

/**Legacy one, TODO: ro be remeoved */
export const getInfluencerPageData = async (slug: string) => {
    return fetch(`/data/${slug}.json`).then(response => response.json())
}

const validInfluencers = [{
    email: "nithesh@folin.io",
    slug: "nithesh",
    password: "Admin123",
    name: "Nithesh"
},
{
    email: "karthiksurya@folin.io",
    slug: "karthiksurya",
    password: "Folin@321",
    name: "Karthik Surya"
},
{
    email: "glamyganga@folin.io",
    slug: "glamyganga",
    password: "Folin@321",
    name: "Glamy Ganga"
},
{
    email: "thoppi@folin.io",
    slug: "thoppi",
    password: "Folin@321",
    name: "Thoppi"
}]