/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Video } from "../../../types/InfluencerTypes";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

type Props = {
  videoSummary: Video[];
};

const VideoViewTrendChart: React.FC<Props> = ({ videoSummary }) => {

 var chartData = getChartOptions(videoSummary)

  return (
    <div className={`card card-stretch mb-5 mb-xxl-8`}>
      <div className="card-body pt-12">   
      <div id="chart">
        <ReactApexChart options={chartData.options as ApexOptions} series={chartData.series} type="line" height={350} />
      </div>
      </div>
    </div>
  );
};

export { VideoViewTrendChart };

function getChartOptions(videoSummary: Video[]) {
  const options = {
    series: [{
      name: 'Views',
      type: 'line',
      data: videoSummary.map(x => x.viewCount)
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [2, 4]
      },
      title: {
        text: 'Recent Video Views Trend'
      },
      dataLabels: {
        enabled: true
      },
      xaxis: {
        categories:videoSummary.map(x => x.publishedAt),
        type: 'datetime'
      }
    },
  };

  return options;
}

