import React, { createContext, useState } from "react";
import { InfluencerSummary, User } from "../types/common";

export type UserContextData = {
    user?: User,
    influencerData?: InfluencerSummary,
    setUser?: (user: User | undefined) => void,
    setInfluencer?: (influencer: InfluencerSummary) => void,
}

export const UserContext: React.Context<UserContextData> = createContext<UserContextData>({});

export const UserContextProvider = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
    const [user, setState] = useState<User | undefined>();
    const [influencerData, setInfluencer] = useState<InfluencerSummary>();

    const setUser = (user: User | undefined) => {
        setState(user)
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser,
                influencerData,
                setInfluencer
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

