import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_start/helpers";

export function AdminAccess({setAccessCode}: {setAccessCode: Function}) {
    useEffect(() => {
        document.body.classList.add("bg-white");
        return () => {
            document.body.classList.remove("bg-white");
        };
    }, []);

    return (
        <div className="d-flex flex-column flex-root">
            <div className="d-flex flex-column flex-lg-row flex-column-fluid" id="kt_login">
                <div className="d-flex flex-column flex-lg-row-auto bg-primary w-lg-600px pt-15 pt-lg-0">
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 text-center">
                        <Link to="/" className="mb-6">
                            <img alt="Logo" src={toAbsoluteUrl("/media/logos/folin-logo-LATEST.jpg")} className="h-75px" />
                        </Link>
                        <h3 className="fw-bolder fs-2x text-white lh-lg">
                            Admin Portal
                            <br />
                        </h3>
                    </div>
                    <div className="d-flex flex-row-fluid bgi-size-contain bgi-no-repeat bgi-position-y-bottom bgi-position-x-center min-h-350px"
                        style={{ backgroundImage: `url(${toAbsoluteUrl("/media/illustrations/winner.png")})`, }}>
                    </div>
                </div>
                <div className="login-content flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7 mx-auto mw-450px w-100">
                    <div className="d-flex flex-column-fluid flex-center py-10">
                        <form className="form w-100" noValidate >
                            {/* begin::Title */}
                            <div className="pb-lg-15">
                                <div className="text-muted fw-bold fs-3">
                                    <div className="v-row mb-10 fv-plugins-icon-container">
                                        <label className="form-label fs-6 fw-bolder text-dark">Access Code</label>
                                        <input
                                            className={"form-control form-control-lg form-control-solid"}
                                            type="password"
                                            name="accessCode"
                                            autoComplete="off"
                                            onChange={(e)=>setAccessCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="pb-lg-0 pb-5">
                                        <button
                                            type="submit"
                                            id="kt_login_signin_form_submit_button"
                                            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                                        ><span className="indicator-label">Sign In</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
