import React, { useContext } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import { RootState } from "../setup";
import { ThemeProvider } from "../_start/layout/core";
import { MasterLayout } from "../_start/layout/MasterLayout";
import { Logout } from "./modules/auth/Logout";
import { PrivateRoutes } from "./routing/PrivateRoutes";
import { PublicRoutes } from "./routing/PublicRoutes";
import { ErrorsPage } from "./modules/errors/ErrorsPage";
import { UserContext } from "../influencer-portal/context/UserContext";

type Props = {
  basename: string;
};

const App: React.FC<Props> = ({ basename }) => {
  const { user } = useContext(UserContext)

  const isAuthorized = !!user

  return (
    <HashRouter>
      <ThemeProvider>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={Logout} />
          {!isAuthorized ? (
            <Route>
              <PublicRoutes />
            </Route>
          ) : (
            <>
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            </>
          )}
        </Switch>
      </ThemeProvider>
    </HashRouter>
  );
};

export { App };
