/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import { Video } from "../../../types/InfluencerTypes";

type Props = {
  videoSummary: Video[];
};

const VideoLikeTrendChart: React.FC<Props> = ({ videoSummary }) => {

 var chartData = getChartOptions(videoSummary)

  return (
    <div className={`card card-stretch mb-5 mb-xxl-8`}>
      <div className="card-body pt-12">   
      <div id="chart">
        <ReactApexChart options={chartData.options as ApexOptions} series={chartData.series} type="line" height={350} />
      </div>
      </div>
    </div>
  );
};

export { VideoLikeTrendChart };

function getChartOptions(videoSummary: Video[]) {
  const options = {
    series: [{
      name: 'Likes',
      type: 'column',
      data: videoSummary.map(x => x.likeCount)
    }, {
      name: 'Comments',
      type: 'line',
      data: videoSummary.map(x => x.commentCount)
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: 'Recent Videos Interaction Trend'
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: videoSummary.map(x => x.publishedAt),
      xaxis: {
        type: 'datetime'
      },
      yaxis: [{
        title: {
          text: 'Like',
        },

      }, {
        opposite: true,
        title: {
          text: 'Comments'
        }
      }]
    },
  };

  return options;
}

