import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { LoginPage } from "../../influencer-portal/pages/LoginPage";
import AdminPage from "../../influencer-portal/pages/AdminPage";

export function PublicRoutes() {
  return (
    <Switch>
      <Route path="/auth" component={LoginPage} />
      <Route path="/admin" component={AdminPage} />
      <Redirect to="/auth" />
    </Switch>
  );
}
