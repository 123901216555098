import React from "react";
import { Link } from "react-router-dom";
import { MenuItem } from "./MenuItem";

export function MenuInner() {
  return (
    <>
      <div className="row">
        <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">Dashboards</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/dashboard" title="Dashboard" />
            </li>
            {/* <li className="menu-item">
              <MenuItem to="/sales" title="Sales" />
            </li>
            <li className="menu-item">
              <MenuItem to="/Expenses" title="Expenses" />
            </li>
            <li className="menu-item">
              <MenuItem to="/Campaign" title="Campaign" />
            </li> */}
          </ul>
        </div>
        {/* <div className="col-sm-4">
          <h3 className="fw-bolder mb-5">General</h3>
          <ul className="menu menu-column menu-fit menu-rounded menu-gray-600 menu-hover-primary menu-active-primary fw-bold fs-6 mb-10">
            <li className="menu-item">
              <MenuItem to="/general/faq" title="FAQ" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/pricing" title="Pricing" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/invoice" title="Invoice" />
            </li>
            <li className="menu-item">
              <MenuItem to="/auth/login" title="Login" />
            </li>
            <li className="menu-item">
              <MenuItem to="/general/wizard" title="Wizard" />
            </li>
            <li className="menu-item">
              <MenuItem to="/error/404" title="Error" />
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
}
