/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { getInfluencerStatistics } from "../../services/data.services";
import { VideoViewTrendChart } from "./charts/VideoViewTrendChart";
import { VideoLikeTrendChart } from "./charts/VideoLikeTrendChart";

export const AdminDashboard: React.FC = () => {
    const [profileId, setProfileId] = useState("")
    const [videoCount, setVideoCount] = useState(20)
    const [loading, setLoading] = useState(false)
    const [influencerSummary, setInfluencerSummary] = useState([])

    const fetchData = async () => {
        try {
            setLoading(true)
            const data = await getInfluencerStatistics(profileId, videoCount);
            setLoading(false)
            setInfluencerSummary(data)
        } catch (e) {
            setLoading(false)
            alert("Failed, please try again with correct id.")
        }
    }

    return (
        <>
            <div className="row g-0 g-xl-5 g-xxl-8">
                <div className="col-xl-12">
                    <div className=" flex-lg-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden py-20 px-10 p-lg-7">
                        <div className="d-flex flex-column-fluid flex-center py-10">
                            <div className="pb-lg-15">
                                <div className="text-muted fw-bold fs-3">
                                    <div className="v-row mb-10 fv-plugins-icon-container">
                                        <label className="form-label fs-6 fw-bolder text-dark">YouTube Id</label>
                                        <input
                                            className={"form-control form-control-lg form-control-solid"}
                                            type="text"
                                            name="profileName"
                                            autoComplete="off"
                                            onChange={(e) => setProfileId(e.target.value)}
                                        />
                                    </div>
                                    <div className="v-row mb-10 fv-plugins-icon-container">
                                        <label className="form-label fs-6 fw-bolder text-dark">Video Count</label>
                                        <input
                                            className={"form-control form-control-lg form-control-solid"}
                                            type="number"
                                            name="videoCount"
                                            autoComplete="off"
                                            value={videoCount}
                                            onChange={(e) => setVideoCount(Number(e.target.value))}
                                        />
                                    </div>
                                    <div className="pb-lg-0 pb-5">
                                        <button
                                            onClick={fetchData}
                                            id="kt_login_signin_form_submit_button"
                                            className="btn btn-primary fw-bolder fs-6 px-8 py-4 my-3 me-3"
                                        ><span className="indicator-label">{loading ? 'Searching...' : 'Search'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-0 g-xl-5 g-xxl-8 pl-8 pr-8">
                <div className="col-xl-6">
                    <VideoViewTrendChart videoSummary={influencerSummary} />
                </div>
                <div className="col-xl-6">
                    <VideoLikeTrendChart videoSummary={influencerSummary} />
                </div>
            </div>
        </>
    );
};
